<template>
    <div class="approvalmanagementdetail-page">
        <div v-if="detailData.after_sale" class="approvalmanagementinfo-body">
            <div class="list-item">
                <p>发货单号：</p>
                <p class="p1">{{ detailData.after_sale.order.order_sn }}</p>
            </div>
            <div class="list-item">
                <p>申请时间：</p>
                <p>{{ detailData.after_sale.created_at }}</p>
            </div>
            <div class="inventorylist-item">
                <p>售后清单：</p>
                <p v-for="(item, index) in detailData.after_sale.poles">{{ item.production }}</p>
            </div>
            <div class="description-item">
                <p>问题描述：</p>
                <p>{{ detailData.after_sale.remark }}</p>
            </div>
            <div class="image-item">
                <p>图片附件：</p>
                <van-grid :border="false" :column-num="3">
                    <van-grid-item v-for="(item, index) in detailData.after_sale.images">
                        <van-image :src="item" />
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div v-if="detailData.status === 0" class="reply-body">
            <div class="replytime-item">
                <p>审批意见：</p>
                <van-cell-group inset>
                    <van-field
                        v-model="remark"
                        autosize
                        class="diybg"
                        maxlength="250"
                        placeholder="请输入售后的内容..."
                        rows="2"
                        show-word-limit
                        type="textarea"
                    />
                </van-cell-group>
            </div>
            <div class="feedback-button">
                <van-button :block="true" color="#DF5F0B" square @click="onApproval(1)">通过审批</van-button>
                <van-button :block="true" color="#D43030" square @click="onApproval(2)">拒绝审批</van-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_APPROVAL_DETAIL, UPDATE_GOODS_APPROVAL_STATUS } from "@/api/goods";
import usePublic from "@/hooks/usePublic";
import { Toast } from "vant/lib/";

let { $route, $router } = usePublic();

onMounted(() => {
    getDetail();
});

// 获取详情
let detailData = ref({});

let getDetail = () => {
    GET_GOODS_APPROVAL_DETAIL({ id: $route.params.id }).then((res) => {
        detailData.value = res.data.data;
        console.log(111, res.data.data);
    });
};

// 审批备注
let remark = ref(null);

let onApproval = (status) => {
    UPDATE_GOODS_APPROVAL_STATUS({
        id: $route.params.id,
        status,
        remark: remark.value
    }).then((res) => {
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "审批成功",
                onClose: () => {
                    $router.go(-1);
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.approvalmanagementdetail-page {
    min-height: 100vh;

    background: #f0f0f0;

    .approvalmanagementinfo-body {
        min-height: 300px;
        background: #fff;
        padding: 20px 15px;

        .list-item {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            color: #000;

            p:nth-child(1) {
                color: #999;
            }

            .p1 {
                color: #df5f0b;
            }

            .p2 {
                color: #43cf7c;
            }
        }

        .inventorylist-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;

            p {
                margin: 10px 0;
                color: #000;
            }

            p:nth-child(1) {
                color: #999;
            }
        }

        .description-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            color: #000;

            p:nth-child(1) {
                color: #999;
                margin-bottom: 10px;
            }
        }

        .image-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;

            p:nth-child(1) {
                color: #999;
                margin-bottom: 10px;
            }
        }
    }

    .reply-body {
        margin-top: 20px;
        min-height: 30px;
        padding: 20px 15px;
        background: #fff;

        .replytime-item {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;

            p:nth-child(1) {
                color: #999;
                margin-bottom: 10px;
            }

            .diybg {
                background: #eee;
            }
        }

        .replyreply-item {
            font-size: 14px;

            p:nth-child(1) {
                color: #999;
                padding-bottom: 10px;
            }
        }

        .feedback-button {
            width: 320px;
            margin: 20px auto 0 auto;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
        }
    }
}
</style>
